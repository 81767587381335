define('compat/console',[],function() {
    "use strict";
    if (window.console) {
        return window.console;
    }
    return {
        log : function() { },
        error : function() { }
    };
});

