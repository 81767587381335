(function(){
    window.FB = window.FB || {
        init: function() {},
        Event: {
            subscribe: function() {}
        },
        XFBML: {
            parse: function() {}
        }
    };
})();

define("social/facebook", (function (global) {
    return function () {
        var ret, fn;
       fn = function () {
                    var FB= this.FB;
                    FB.init({
                        appId      : '258772054174865', // App ID from the App Dashboard
                        channelUrl : '/channel.html', // Channel File for x-domain communication
                        status     : true, // check the login status upon init?
                        cookie     : true, // set sessions cookies to allow your server to access the session?
                        xfbml      : true  // parse XFBML tags on this page?
                    });
                    return FB;
                };
        ret = fn.apply(global, arguments);
        return ret || global.FB;
    };
}(this)));

