define('libs/playlist',[
       'Backbone',
       'underscore',
       'jQuery',
       'libs/jquery/jplayer',
       'libs/mustache'
], function( Backbone, _ , $, jPlayer, Mustache ) {
    "use strict";
    var PlaylistItemView = Backbone.View.extend({
        tagName : 'li',
        template : Mustache.compile(
            '<div>' +
            '<a href="javascript:;" class="jp-playlist-item-remove">&times;</a>' +
            '<a href="javascript:;" class="jp-playlist-item" tabindex="1">' +
            '{{ title }} <em>{{ description }}</em> ' +
            '{{# artist }} <span class="jp-artist"> by {{ artist }} </span>{{/artist}} </a>' +
            '</div>'
        ),
        events : {
            'click a.jp-playlist-item-remove' : 'remove',
            'click a.jp-playlist-item' : 'play'
        },
        initialize : function( options ) {
            this.media =  options.model;
        },
        render : function() {
            this.$el.append( this.template( this.media ));
            return this;
        },
        remove : function() {
            this.trigger( 'remove' , this);
            return false;
        },
        play : function( ev ) {
            this.highlight();
            this.trigger( 'play', this, this.media );
            if( ev) {
                $(ev.target).blur();
            }
        },
        highlight : function() {
            this.$el.addClass("jp-playlist-current");
            this.trigger('highlight', this);
        },
        unHighlight : function() {
            this.$el.removeClass("jp-playlist-current");
        },
        flash: function(){
            this.$el.show().fadeOut(2000);
        }
    });

    var PlaylistView = Backbone.View.extend({
        events : {
            'mouseenter' : 'show',
            'mouseleave' : 'hide',
            'click .jp-previous' : 'previous',
            'click .jp-next' : 'next'
        },
        jPlayerSelector : '#jplayer',
        ItemView : PlaylistItemView,
        defaultJplayerOptions : {
            'cssSelectorAncestor' : '#jplaylist'
        },
        initialize : function( options ) {
            this.setElement( '#jplaylist' );

            this.loop = false; // Flag used with the jPlayer repeat event

            this.current = null;
            this.playlist = [];
            // Array of Objects: The current playlist displayed (Un-shuffled or Shuffled)

            // Override the default repeat event handler
            this.options.repeat = _.bind( function(event) {
                this.loop = event.jPlayer.options.loop;
            }, this);
            var jPlayerOptions = _.extend( {}, this.defaultJplayerOptions, this.options );

            // Create a ready event handler to initialize the playlist
            this.jPlayer = $(this.jPlayerSelector)
            .jPlayer( jPlayerOptions )
            .bind($.jPlayer.event.ready, _.bind( this._init, this))
            .bind($.jPlayer.event.ended, _.bind( this.next, this))
            .bind($.jPlayer.event.play, function(event) {
                $(this).jPlayer("pauseOthers");
            })
            .bind($.jPlayer.event.resize, function(event) {
                if(event.jPlayer.options.fullScreen) {
                    this.$('.jp-title').show();
                } else {
                    this.$('.jp-title').hide();
                }
            });

            // Remove the empty <li> from the page HTML. Allows page to be valid HTML, while not interfereing with display animations
            this.$('.jp-playlist ul').empty();
        },
        _init: function() {
            if (this.current) {
                if(this.options.autoPlay) {
                    this.current.play();
                } else {
                    this.current.highlight();
                }
            }
        },
        add: function( media, playNow) {
            var playerItem = new this.ItemView({ model : media });
            return this._add( playerItem, playNow);
        },
        _add : function( playerItem, playNow ) {
            playerItem
            .on( 'play' , this.onPlay, this)
            .on( 'highlight', this.cleanHighlight, this)
            .on( 'remove', this.onRemove, this);

            this._insert( playerItem );
            if(playNow || this.options.autoPlay && this.playlist.length === 1 ) {
                playerItem.play();
            } else if(this.playlist.length === 1) {
                playerItem.highlight();
            } else if ( ! _.contains( this.itemShown().toArray(), playerItem.el ) ){
                playerItem.flash();
            }
            this.$el.show();
            return playerItem;
        },
        _insert : function( view) {
            this.playlist.push( view );
            this.$('.jp-playlist ul').append(
                view.render().el);
        },
        cleanHighlight : function( view ) {
            var playlist = view ? _.without( this.playlist, view) : this.playlist;
            _.each(playlist, function( v ) { v.unHighlight();} );
            if ( ! this.shown ) {
                this.hide();
            }
        },
        onPlay: function( view, media ) {
            this.current = view;
            if ( ! _.contains( this.playlist, view)) {
                this._insert( view );
            }
            this.jPlayer
            .jPlayer( 'setMedia', media )
            .jPlayer( 'play' );
            this.$('img.cover').attr( 'src' , view.media.poster );
            this.$('li.artist').html(view.media.artist);
            this.$('li.title').html(view.media.title);
            this.$('li.title-edit').html(view.media.description);
            this.$('a.download').attr('href', view.media.dl);
        },
        pause: function() {
            this.jPlayer.jPlayer('pause');
        },
        next: function( ev ) {
            if( ev ) {
                $( ev.target ).blur();
            }
            if ( this.current === null) {
                if ( this.playlist.length ) {
                    this.playlist[0].play();
                }
                return;
            }
            var index = _.indexOf( this.playlist, this.current) + 1;
            if ( index === this.playlist.length) {
                //play the last track
                this.current.unHighlight();
                this.jPlayer.jPlayer('stop');
            }
            else {
                this.playlist[ index ].play();
            }
        },
        previous: function(ev) {
            if( ev ) {
                $( ev.target ).blur();
            }
            if ( this.current === null) {
                if ( this.playlist.length ) {
                    this.playlist[0].play();
                }
                return;
            }
            var index = _.indexOf( this.playlist, this.current) - 1 ;
            if ( index  ) {
                //play the last track
                this.current.unHighlight();
                this.jPlayer.jPlayer('stop');
            }
            else {
                this.playlist[ index ].play();
            }
        },
        onRemove: function( view ) {
            if ( view === this.current) {
                this.next();
            }
            view.$el.detach();
            var index = _.indexOf( this.playlist, view);
            this.playlist.splice( index );
            if (this.playlist.length === 0) {
                this.$('img.cover').attr( 'src' , "");
                this.$('li.artist').html("");
                this.$('li.title').html("");
                this.$('li.title-edit').html("");
                this.$('a.download').attr('href', "");
                this.jPlayer.jPlayer( 'setMedia', "" );
            }
        },
        itemShown: function() {
            return this.$('.jp-playlist-current').next().andSelf();
        },
        show: function() {
            this.shown=true;
            this.$('.jp-playlist li').stop( true, true).slideDown(100);
        },
        hide: function() {
            this.shown=false;
            this.$('.jp-playlist li').not( this.itemShown().show() ).slideUp();
        },
        detach: function() {
            this.removeClass('pinned');
        },
        attach: function() {
            this.addClass('pinned');
        }
    });
    return {
        'Playlist' : PlaylistView,
        'Item' : PlaylistItemView
    };
});

