define('loader/musibox',[
       'apps/menu',
       'apps/filepublishing',
       'apps/musibox'
], function(menu, fp, mb) {
    "use strict";
    return {
        menu : menu,
        filepublishing: fp,
        musibox: mb
    };
});

