define('views/accounts/styles',[
       'Backbone',
       'underscore',
       'jQuery'
], function(Backbone, _, $) {
    "use strict";
    var StyleSelector = Backbone.View.extend({
        events : {
            'click .style' :'select'
        },
        initialize: function( options){
            this.url = this.$el.attr('action');
            this.form = this.$el.serializeArray();
        },
        select : function( ev) {
            var target = $(ev.currentTarget);
            var selected = ! target.hasClass('selected');
            var id = target.find('a').attr('href').split('=').pop();

            var values = [
                { name : 'style_id' , value : id },
                { name : 'selected' , value : selected && 'on'  }
            ];

            $.ajax({
                type: 'POST',
                url : this.url,
                data : values.concat( this.form ),
                success: function() {
                    target.toggleClass('selected', selected);
                }
            });
            return false;
        }
    });
    return function(el ) {
        return new StyleSelector({
            el : el
        });
    };
});

