define('compat/warning',[
       'jQuery',
       'underscore',
       'compat/console',
       'libs/mustache',
       'libs/jquery/cookie'
], function($, _, console, mustache, cookie) {
    "use strict";
    var warningTemplate = mustache.compile(
        '<div id="compat-{{topic}}" class="compat-warning">'+
        'Your browser does not support {{ topic }}. It may disrupt your navigation' +
        '</div>'
    );
    var IgnoredWarnings = {
        _key :  'ignored-warnings',
        _get: function() {
            if ( !( '_all' in this )) {
                var all = cookie( this._key);
                this._all = all ? all.split(';') : [];
            }
            return this._all;
        },
        _save: function() {
            cookie( this._key, this._get().join( ';'),{
                expires : 365,
                path : '/'
            });
        },
        add: function( ignored) {
            this._get().push( ignored);
            this._save();
        },
        contains: function( ignored) {
            return _.contains( this._get(), ignored);
        }
    };
    return function( topic) {
        console.error( 'has not', topic);
        if ( IgnoredWarnings.contains( topic)) {
            return;
        }
        var warning = $('#compat-' + topic);
        if (! warning.length ) {
            warning = $(
                warningTemplate({
                topic: topic
            })).appendTo( '#compat-warnings');
        }
        if ( warning.find( 'a.ignore').length === 0) {
            $( '<a href="#" class="ignore">Ok</a>')
            .click( function() {
                warning.hide();
                IgnoredWarnings.add( topic );
                return false;
            })
            .appendTo( warning);
        }
        warning.show();
    };
});

