define('views/musibox/tops',[
       'jQuery',
       'libs/jquery/tipsy'
], function( $, $tp ) {
    "use strict";
    return function() {
        $('.new-releases img').tipsy({
            live: true,
            title : 'alt',
            gravity: $tp.autoNS
        });
    };
});

