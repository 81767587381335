define('views/accounts/profile',[
       'jQuery',
       'apps/musibox'
], function($,musibox) {
    "use strict";
    return function( el ){
        $(el).change(function() {
            musibox.navigate(this.value);
            return false;
        });
    };
});

