define('views/events',[
       'Backbone',
       'jQuery',
       'libs/jquery/tipsy',
       'apps/social'
], function( Backbone, $, $ts, social) {
    "use strict";
    var EventSearchView = Backbone.View.extend({
        events : {
            'click .submit' : 'search'
        },
        search: function() {
            this.$el.submit();
            return false;
        }
    });
    return function(el) {
        var $el = $(el);
        $el.find('.contact').tipsy({
            gravity: $ts.autoNS
        });
        $el.find('.budget').tipsy({
            live: true,
            gravity: 'w',
            title : function() {
                return $(this).find('.tip-text').text();
            }
        });
        social.refresh();
        return new EventSearchView({ el: $el.find('.search-form') });
    };
});

