
define('views/filepublishing/rating',[
       'views/filepublishing/itembar',
       'underscore',
       'jQuery'
], function( ItemBar, _, $) {
    "use strict";
    var BaseRatingView = ItemBar.extend({
        events : _.extend( {
            'click .rating' : 'select'
        }, ItemBar.prototype.events ),
        initialize : function( options ){
            this.model.on( 'change:rating:rating', this.changeRating, this );
        },
        changeRating : function() {
            var rating = this.model.getRating();
            if (! rating) {
                this.$el.addClass( 'unset').removeClass('set');
                return;
            }

            this.$el.addClass( 'set').removeClass('unset');
            //get the 'rating'th element from the end
            this.$('li')
            .removeClass('selected')
            .eq( -rating)
            .addClass('selected');
        },
        render : function() {
            this.changeRating();
            return this;
        },
        cleanHover : function() {
            if ( ! this.model.getRating( 'rating' ) ) {
                this.$( 'img' ).attr( 'src' , this.blank );
            } else {
                this.changeRating();
            }
        },
        select : function( ev ) {
            if ( this.model ) {
                var rating = $(ev.currentTarget).attr('href').split('=').pop();
                this.model.setRating( parseInt(rating, 10) );
            }
            return false;
        }
    });
    var RatingView = BaseRatingView.extend({
    });
    var PlayerRatingView = BaseRatingView.extend({
    });
    return {
        'Listing' : RatingView,
        'Player' : PlayerRatingView
    };
});

