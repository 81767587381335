define('views/playlist',[
       'libs/playlist',
       'underscore',
       'jQuery'
], function( PlaylistViews, _, $ ) {
    "use strict";
    var PlaylistItemView = PlaylistViews.Item.extend({
        className : 'mb-playlist-item',
        initialize : function(options) {

            this.havePlayed = false;
            this.doNotPlay = false;
            this.on( 'play', this.onPlay, this);

            var fm = options.filemedia;
            var mp3 = options.mp3;
            var media = {
                id  : fm.id,
                title : mp3.get( 'title' ),
                description : fm.description,
                poster : mp3.get( 'icone' ),
                artist : mp3.get('artist'),
                mp3 : this._decrypt( fm.mp3 ),
                dl : this._decrypt( fm.dl )
            };
            /*
            this.ratingView = new RatingViews.Player({
                'model' : mp3,
                'type' : 'perso'
            });
            this.preferedSelector = new PreferedSelectors.Player({
                'model' : mp3,
                'filemedia' : fm
            });
           */
            PlaylistItemView.__super__.initialize.call( this, { model : media });
        },
        play : function() {
            if ( this.doNotPlay ) {
                return;
            }
            PlaylistItemView.__super__.play.call( this );
        },
        _decrypt : function( cryptUrl ) {
            var mp3Url = '';
            for(var a=0;a<cryptUrl.length;a+=2) {
                mp3Url += String.fromCharCode(parseInt('0x'+cryptUrl.substr(a,2)));
            }
            return mp3Url;
        },
        onPlay : function() {
            if ( !this.havePlayed ) {
                this.havePlayed = true;
                this.trigger( 'firstPlay', this);
            }
        },
        render : function() {
            PlaylistItemView.__super__.render.call( this );
            this.$el
            .append(
                $('<div />')
                .addClass( 'mb-playlist-rating')
                //.append( this.ratingView.render().el)
                //.append( this.preferedSelector.render().el)
            );
            return this;
        },
        disable : function() {
            this.doNotPlay = true;
        }
    });
    var PlaylistView = PlaylistViews.Playlist.extend({
        Mp3ItemView : PlaylistItemView,
        addMp3 : function( mp3, fm, playNow ) {
            var itemView = new this.Mp3ItemView({ 'mp3' : mp3, 'filemedia' : fm });
            itemView.on( 'firstPlay', this.firstPlay, this);
            this._add( itemView, playNow);
            return itemView;
        },
        firstPlay : function( view ) {
            this.trigger( 'played', view.model);
        },
        disablePlayer : function() {
            _.each( this.playlist, function( v ) {
                if ( ! v.havePlayed) {
                    v.disable();
                }
            });
        },
        dump: function() {
            var instance = this.jPlayer.data('jPlayer');
            return {
                flash : {
                    play:'mp3' in instance.html.canPlay,
                    used: instance.html.used
                },
                html: {
                    play : 'mp3' in instance.flash.canPlay,
                    used : instance.flash.used
                }
            };
        }
    });
    return PlaylistView;
});

