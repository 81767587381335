
define('views/filepublishing/itembar',[
       'Backbone',
       'jQuery',
       'underscore'
], function(Backbone, $, _)  {
    "use strict";
    var ItemBar = Backbone.View.extend({
        events : {
            'mouseenter .label' : 'open',
            'mouseleave' : 'close',
            'click .label' : 'setOpen'
        },
        close: function() {
            if ( !this._forced) {
                if ( ! this.closing ) {
                    this.closing = true;
                    this.$('.list').fadeOut('slow', function() {
                        if ( this.closing) {
                            this.closing = false;
                            this.trigger('closed');
                        } else {
                            this.open();
                        }
                    }.bind(this));
                }
            }
        },
        setOpen: function( state) {
            if ( _.isBoolean( state)){
                this._forced = state;
            } else {
                this._forced = ! this._forced;
            }
            if ( this._forced) {
                this.open();
            } else {
                this.close();
            }
            return false;
        },
        open: function(f){
            if ( !this._forced) {
                this.closing = false;
                this.$('.list').show();
                this.trigger('open');
            }
        }
    });
    return ItemBar;
});

