define('compat/has',[
       'underscore',
       'compat/warning'
], function( _, warn) {
    "use strict";
    var has = function( key ) {
        var tokens = key.split('.');
        var hasFeature = _.reduce(tokens, function( context, attribute) {
            if ( ! context ) {
                return false;
            }
            return context[attribute];
        }, window );

        if ( ! hasFeature) {
            warn( _.last(tokens).toLowerCase() );
        }
        return hasFeature;
    };
    return has;
});

