define('views/filepublishing/inmymusibox',[
       'Backbone'
], function( Backbone) {
    "use strict";
    var InMyMusiboxView = Backbone.View.extend({
        events : {
            'click' : 'select'
        },
        initialize: function() {
            this.model.on( 'change:rating:in_my_musibox', this.render, this);
        },
        render: function() {
            if ( this.model.isInMyMusibox() ) {
                this.$el.addClass( 'in').removeClass( 'out');
            } else {
                this.$el.removeClass( 'in').addClass( 'out');
            }
            return this;
        },
        select: function() {
            this.model.setInMyMusibox( !this.model.isInMyMusibox());
            return false;
        }
    });
    return InMyMusiboxView;
});

