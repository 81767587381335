define('apps/filepublishing',[
       'Backbone',
       'jQuery',
       'apps/musibox',
       'apps/player',
       'views/filepublishing/main',
       'views/filepublishing/messagecount',
       'models/mp3s',
       'compat/json',
       'domReady!',
       'apps/social',
       'apps/stream'
], function( Backbone, $, mainView, player, FilepublishingViews, MessageCounterView, Mp3s, JSON, doc, social, stream ) {
    "use strict";
    var FpApp = Backbone.View.extend({
        getView: function(){
            if ( this.isLogged) {
                return FilepublishingViews.logged;
            } else {
                return FilepublishingViews.anon;
            }
        },
        initialize: function() {
            var hostname = location.hostname;
            this.isLogged = $('body.logged').length !== 0 ;
            mainView.on( 'page-changed', this.reset, this);
            this.playlistItems = {};
            player.on('played', this.played, this);
            this.reset();
        },
        disablePlayers: function() {
            if ( this.filepublishing) {
                this.filepublishing.disablePlayers();
            }
        },
        reset: function() {
            this.clean();
            var mp3s = this.getMp3s();
            if ( mp3s !== null) {
                Mp3s.reset( mp3s);

                var ViewClass = this.getView();
                this.filepublishing = new ViewClass();
                this.filepublishing.addAll( Mp3s);
                this.filepublishing.on( 'play', this.play, this);
            } else {
                Mp3s.reset();
                this.filepublishing = null;
            }
            if ( this.remaining ) {
                this.remaining.unbind();
            }
            var remaining = $('#fpremaining');
            if ( remaining.length ) {
                this.remaining = new MessageCounterView({ el: remaining })
                .on('zero', this.disablePlayers, this)
                .render();
            } else {
                this.remaining = null;
            }
            social.refresh();
        },
        played: function() {
            if ( this.remaining ) {
                this.remaining.decrement();
            }
        },
        play: function( model, fm) {
            if ( fm.id in this.playlistItems ) {
                this.playlistItems[ fm.id].play();
            } else {
                this.playlistItems[ fm.id] = player.addMp3( model, fm);
            }
        },
        getMp3s: function() {
            var store =  $('#mp3-dump');
            if ( store.length ) {
                store.remove();
                return JSON.parse( store.text());
            } else {
                return null;
            }
        },
        clean: function() {
            if ( this.filepublishing) {
                this.filepublishing.unbind();
                Mp3s.unbind();
            }
        }
    });
    return new FpApp();
});

