define('views/filepublishing/player',[
       'views/filepublishing/itembar',
       'underscore',
       'jQuery'
], function( ItemBar, _, $ ) {
    "use strict";
    var FilemediaPlayerView = ItemBar.extend({
        events : _.extend({}, ItemBar.prototype.events, {
            'click .label' : 'launchFirst',
            'click .player' : 'launch',
            'click .download-link' : 'onDownload'
        }),
        render : function() {
            return this;
        },
        initialize : function() {
            this.doNotPlay = false;
        },
        launch : function( ev ) {
            this._launch( $(ev.currentTarget));
            return false;
        },
        launchFirst : function(){
            this._launch( this.$('.player').first());
            return false;
        },
        _launch: function( el) {
            if ( this.doNotPlay ) {
                return;
            }
            var id = el
            .find('.player-link')
            .attr('href').split('=').pop();
            id = parseInt( id, 10);
            var model = _.find( this.model.get('filemedias'), function( fm ) {
                return fm.id === id;
            });
            this.trigger( 'play', model );
        },
        onDownload : function( ){
            this.trigger( 'download', this.model);
        },
        disable : function() {
            this.doNotPlay = true;
        }
    });
    return FilemediaPlayerView ;
});

