define('views/musibox/loadrequired',[
       'Backbone',
       'jQuery',
       'underscore',
       'compat/console',
       'require'
], function( Backbone, $, _, console, require) {
    "use strict";
    var RequiredLoader = {
        catchError: true,
        load : function() {
            var promises = $('.load-required')
            .removeClass('load-required')
            .map( this._load.bind( this));

            return $.when( promises);
        },
        _load: function( i, element ) {
            var dependency = $(element).data('require');
            var deferred = new $.Deferred();
            this.trigger( 'required-loading', dependency, deferred);
            require([
                    dependency
            ], function( method ) {
                if ( ! method) {
                    this.fail( 'not callable', dependency, element);
                    return;
                }
                try {
                    method( element );
                    this.win( dependency, element);
                } catch(e) {
                    if (this.catchError) {
                        this.fail( 'runtime error', dependency, element);
                    } else {
                        throw e;
                    }
                }
                deferred.resolve();
            }.bind( this),
            this.catchError && function( err ) {
                this.fail( err.requireType, dependency, element);
                deferred.reject();
            }.bind( this));
            return deferred.promise();
        },
        win: function( dependency, el) {
            this.trigger('loaded', dependency, el);
        },
        fail: function( cause, dependency, el) {
            console.error( 'dependency', dependency, 'of', el, 'failed because', cause);
            this.trigger('failed', dependency, el);
        }
    };
    _.extend( RequiredLoader,  Backbone.Events);
    return RequiredLoader;
});

