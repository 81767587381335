define('views/accounts/residence',[
       'jQuery'
], function( $ ) {
    return function( el ) {
        return $(el).on('click', 'a', function( ev) {
            var target = $(ev.currentTarget);
            target.toggleClass('zoom');
            return false;
        });
    };
});

