define('views/filepublishing/favorites',[
       'views/filepublishing/itembar',
       'underscore',
       'libs/jquery/tipsy'
], function( ItemBar, _, $ts) {
    "use strict";
    var FavoriteView = ItemBar.extend({
        events: _.extend(
            {},
            ItemBar.prototype.events,
            {
                'click .label': 'clickLabel'
            }),
        initialize: function(){
            this.setElement('#my-favorite-styles');
            this.$('.label').tipsy({
                gravity: 's'
            });
        },
        clickLabel: function() {
            //override default, trigger a link change
            return true;
        }
    });
    return FavoriteView;
});

