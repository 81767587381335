define('apps/playlist',[
       'Backbone',
       'underscore',
       'jQuery',
       'views/search'
], function( Backbone, _, $, SearchBar){
    "use strict";
    var PlaylistSelection = Backbone.View.extend({
        events : {
            'click .track img' : 'select',
            'click #clearall' : 'clearall',
            'click #details' : 'showDetails'
        },
        initialize: function(){
            this.setElement('#playlist');
            this.search = new SearchBar({
                el : this.$( '.filter-box')
            })
            .on('filter', this.filter, this)
            .on('clear', this.filter, this);

            this.details = false;
        },
        _sendRequest: function( mp3ids, selected ) {
            var form = this.$('#playlist-form');
            var values = [
                { name : 'selected' , value : selected  }
            ];
            _.each( mp3ids, function(mp3id)  {
                values.push({ name : 'mp3id' , value : mp3id });
            });
            $.ajax({
                'type' : form.attr('method'),
                'url' : form.attr('action'),
                'data' : values.concat( form.serializeArray() ),
                'success' : function(data) {
                },
                'error' : function(xhr, error, txtStatus) {
                    alert( txtStatus );
                }
            });
        },
        clearall : function() {
            var ids = this.$('.selected')
            .addClass('unselected')
            .removeClass('selected')
            .map( function() {
                return $(this).attr('id').split('-').pop();
            });
            if (ids) {
                this._sendRequest(ids, false);
            }
            return false;
        },
        select : function(ev) {
            var element = $(ev.currentTarget);
            element.toggleClass('selected').toggleClass('unselected');
            var selected = element.hasClass( 'selected');
            var id = element.attr('id').split('-').pop();
            this._sendRequest([ id ], selected);
            return false;
        },
        filter: function( needle ){
            var haystack = this.$('.track');
            if (  _.isUndefined( needle)) {
                haystack.show();
                return;
            }
            var needles = _.compact(needle.split(' '));
            var toLowerCase = String.toLowerCase || function( str ) {
                //Fallback for ... Guess Who ... IE of course
                return str.toLowerCase();
            };
            needles = _.map( needles, toLowerCase);
            _.chain( haystack.toArray())
            .each( function( e ) {
                e =  $(e);
                var performer = e.find('.artiste').text().toLowerCase();
                var title = e.find('.title').text().toLowerCase();
                var show = _.all( needles, function( needle ) {
                    return ( performer.indexOf( needle) !== -1 || title.indexOf( needle) !== -1);
                });
                e.toggle( show);
            });
        },
        showDetails: function() {
            if( this.details ){
                this.$el.removeClass('more-details');
                this.$('#details').text("Afficher les details");
            } else {
                this.$el.addClass('more-details');
                this.$('#details').text("Masquer les detail");
            }
            this.details = ! this.details;
        }
    });


    var init = function(){
        var pl = new PlaylistSelection();
        pl.render();
    };
    return init;
});

