/*globals require: true, document: false */

define('views/musibox/navigation',[
       'Backbone',
       'jQuery',
       'compat/console',
       'compat/domparser'
], function( Backbone, $, console, parseDOM ) {
    "use strict";
    var MusiboxLiveRouter = Backbone.Router.extend({
        routes : {
            '*default' : 'goToPage'
        },
        goToPage : function( url ) {
            this.trigger('navigate-to', url);
        }
    });
    var MusiboxLiveView = Backbone.View.extend({
        events : {
            'click a' : 'linkClick',
            'submit form': 'submitForm'
        },
        initialize : function() {
            this.setElement( 'body' );
            this.router = new MusiboxLiveRouter();
            this.router.on('navigate-to', this.goTo, this);
            Backbone.history.start({
                pushState: true,
                silent : true,
                hashChange: false
            });
            this.on( 'page-changed', this.scrollToTop, this);
        },
        scrollToTop : function() {
            $(document).scrollTop(0);
        },
        getCurrent: function() {
            var currentUrl = Backbone.history.fragment;
            if ( currentUrl[0] !== '/') {
                return '/' +  currentUrl;
            }
            return currentUrl;
        },
        getPath: function() {
            return this.getCurrent().split('?').shift();
        },
        _outOfDomain: function(url) {
            if (url.match(/^.*\.[a-zA-Z0-9]+([?].+)?$/)){
                return true;
            }
            if (url.indexOf('http:') === 0 || url.indexOf('mailto:') === 0 || url.indexOf('https:') === 0 ){
                return true;
            }
            if (url.match("admin/")){
                return true;
            }
            if (url.match("logout/")){
                return true;
            }
            return false;
        },
        linkClick : function( ev ) {
            var url = ev.currentTarget.getAttribute('href');
            if (url === this.getCurrent() ){
                return false;
            }
            if (this._outOfDomain(url)) {
                return true;
            }
            if (url.indexOf('javascript:') === 0 ){
                return false;
            }
            if( url.charAt(0) === '#' ) {
                console.info('Got your back bro', url);
                return false;
            }
            if ( url.charAt( 0) === '?' ){
                url =  this.getPath() + url;
            }

            this.goTo(url);
            return false;
        },
        submitForm : function(ev) {
            var form = $( ev.currentTarget);
            if ( ( form.attr('method') || 'get' ).toLowerCase() === 'post' ) {
                return true;
            }
            var action = form.attr('action');
            if ( ! action) {
                action = this.getPath();
            }
            if (this._outOfDomain(url)) {
                return true;
            }

            var params = form.serialize();
            var url = action + '?' + params;
            this.goTo(url);
            return false;
        },
        goTo: function( url ){
            this.trigger( 'navigate-to', url);
        },
        reload: function() {
            this.navigateTo( this.getCurrent());
        },
        navigateTo: function( url ) {
            //console.info( 'Going to', url);
            if ( url[0] !== '/') {
                url = '/' + url;
            }
            this.router.navigate( url);
            var promise = $.ajax({
                'type' : 'GET',
                'url' : url
            }).fail(
            this.fail.bind( this)
            ).pipe(
            this.success.bind( this)
            );
            this.trigger('page-loading', url, promise);
            return promise;
        },
        fail: function(data, status, error ) {
            console.error(error);
            this.$('#content').empty().append( $('<pre/>').append( data.responseText));
            this.trigger( 'page-loaded' );
        },
        success : function( original ) {
            var data = parseDOM(original);
            var title = data.find('title').text();
            if ( title ) {
                document.title = title;
            }

            var comingTop = data.find('#top');
            this.$('#top').replaceWith(comingTop);

            var content = data.find('#content');
            this.$('#content').replaceWith( content);

            this.trigger( 'page-changed' );
            this.trigger( 'page-loaded' );
        }
    });
    return MusiboxLiveView;
});

