define( 'views/autocompletemultiple',[
       'jQuery',
       'libs/mustache',
       'libs/jquery/jquery-ui',
       'domReady!'
], function( $ , Mustache, $ui, doc ){
    "use strict";
    return function(element) {
        var $el = $(element);
        var name = $el.data('name');
        var template = Mustache.compile(
            '<span class="ac-new">{{ item.value }}' +
            '<input type="hidden" name="{{name}}" value="{{ item.id }}" />' +
            '<a href="#" class="remove">&times;</a>' +
            '</span>'
        );
        $el
        .on('click', '.ac-new .remove', function( ev ) {
            $(ev.currentTarget).parent().remove();
            return false;
        })
        .on('click', '.ac-value .remove', function( ev ) {
            var $that = $(ev.currentTarget).blur().parent();
            var input = $that.find('input');
            var removeHandle = $that.find('.remove');

            if (!input.attr('disabled')) {
                $that.css('color','gray');
                input.attr('disabled',true);
                removeHandle.text('+');
            } else {
                $that.css('color','');
                input.attr('disabled',false);
                removeHandle.html('&times;');
            }
            return false;
        })
        .find( '.input')
        .autocomplete({
            delay:100,
            select : function(ev, ui) {
                if (ui.item) {
                   $el.find('.store').append( template({
                      item: ui.item,
                      name: name
                   }) );
                   $el.find('[type=text]').val('');
                }
            },
            source : $el.data('source'),
            minLength : $el.data('min-length')
        });
    };
});

