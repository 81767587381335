(function(){
    window.gapi = window.gapi || {
        plusone: {
            go: function() {}
        }
    };
})();

define("social/google", (function (global) {
    return function () {
        var ret, fn;
        return ret || global.gapi;
    };
}(this)));

