define('apps/musibox',[
       'Backbone',
       'underscore',
       'jQuery',
       'views/musibox/navigation',
       'views/musibox/chat',
       'views/musibox/loadrequired',
       'views/musibox/loadingindicator',
       'views/musibox/tops',
       'compat/pushstate',
       'compat/console',
       'compat/json',
       'domReady!'
], function( Backbone, _, $, NavigationView, ChatView,
            RequiredLoader, LoadingIndicator, activateTops,
           hasPushState, console, JSON, doc ) {
    "use strict";
    var MusiboxLive = {
        loadingIndicator: new LoadingIndicator(),
        navigate : function( url ) {
            if ( ! this.navigation) {
                window.location = url;
                return;
            }
            this._navigate( url);
        },
        reload: function() {
            if( ! this.navigation) {
                window.location.reload();
                return;
            }
            this.navigation.reload();
        },
        _navigate : function( url ) {
            this.loadingIndicator.clean().show();

            var promise = this.navigation
            .navigateTo( url)
            .pipe( this.loadRequired.bind( this))
            .then( this.firePageChanged.bind(this) );

            this.hideAfter( promise);
        },
        firePageChanged: function() {
            this.trigger('page-changed');
        },
        hideAfter : function( promise ) {
            return promise.always(
                this.loadingIndicator.hide.bind( this.loadingIndicator) );
        },
        loadRequired : function() {
            return RequiredLoader.load() ;
        },
        gotError: function( error, file, line) {
            console.error( 'OMG FAIL', error, file, line);
            $.ajax({
                type: 'POST',
                url : '/report-error',
                data : {
                    error : error,
                    file: file,
                    line : line,
                    agent : window.navigator.userAgent,
                    version: window.versionTag,
                    jplayer: JSON.stringify( require('apps/player').dump())
                }
            });
            return false;
        },
        initialize: function() {
            window.onerror = this.gotError.bind(this);
            require.onError = function( err ) {
                this.gotError( 'Require Error:' + err.requireType, 'requirejs', err.requireModules);
            }.bind(this);

            $('body').on( 'click', '#a', function( ) { return window.lets.make.an.error; });
            this.loadingIndicator.show();
            RequiredLoader
            .on( 'required-loading', this.loadingIndicator.addLoading.bind( this.loadingIndicator));
            activateTops();
            if ( $('#chat').length !== 0) {
                this.chatView = new ChatView();
            }
            if ( hasPushState){
                this.navigation = new NavigationView();
                this.navigation
                .on( 'navigate-to', this._navigate, this)
                .on( 'page-loading', this.loadingIndicator.addLoading.bind( this.loadingIndicator));

            }
            this.hideAfter( this.loadRequired() );
            return this;
        }
    };
    _.extend( MusiboxLive, Backbone.Events);
    return MusiboxLive.initialize();
});

