define('views/stream',[
       'jQuery',
       'libs/socketio',
       'compat/console'
], function($, io, console) {
    "use strict";
    var COUNT = 10;
    var host = location.hostname;
    if (location.port) {
        host += ':9000';
    }
    try {
        var socket = io.connect(host + '/stream');
    } catch(e) {
        return function(el) {
            if (location.hostname !== 'localhost') {
                console.error(el, e);
            }
        };
    }

    var StreamView = function(el) {
        this.$el = $(el);
    };
    StreamView.prototype = {
        getLang: function() {
            return this.$el.data('lang') || 'en';
        },
        append: function(html) {
            var items = this.$el.children();
            if (items.length >= COUNT) {
                items.last().remove();
            }
            this.$el.prepend(html);
        }
    };

    return function(el){
        var sv = new StreamView(el);
        socket.on('connect', function() {
            socket.emit('setlang', sv.getLang());
        });
        socket.on('stream', sv.append.bind(sv));
    };
});

