define('apps/menu',[
       'Backbone',
       'jQuery',
       'domReady!'
], function( Backbone, $, doc ) {
    "use strict";
    var ItemBar = Backbone.View.extend({
        events : {
            'mouseenter .label' : 'open',
            'mouseleave' : 'close'
        },
        close: function() {
            this.$('.list').hide();
        },
        open: function(f){
            this.$('.list').fadeIn();
        }
    });

    var MenuBar = Backbone.View.extend({
        events: {
            'click #languages-form a' : 'changeLangage'
        },
        initialize: function() {
            this.setElement('#menu');
            this.tabs = this.$('> .menu').map(function(i, el) {
                return new ItemBar({
                    el: el
                });
            });
        },
        changeLangage : function(ev){
            $('#languages-language').attr('value',$(ev.currentTarget).attr("href"));
            $('#languages-form').submit();
            return false;
        }
    });
    return new MenuBar();
});

