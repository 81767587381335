define('views/filepublishing/messagecount',[
       'Backbone',
       'jQuery'
], function( Backbone, $ ) {
    "use strict";
    // --------------------------- Views
    var MessageCounterView = Backbone.View.extend({
        initialize : function() {
            this.count = parseInt( this.$('.messagecount').text(), 10 );
        },
        decrement : function() {
            if ( this.count === 0) {
                return this;
            }
            this.count -= 1 ;
            this.trigger( 'decrement', this.count );
            this._update();
            return this;
        },
        render : function() {
            this._update();
            return this;
        },
        _update : function() {
            //console.log( this.count )
            if ( this.count === 0) {
                this.$('.msg-0').show();
                this.$('.msg-1').hide();
                this.$('.msg-s').hide();
                this.trigger( 'zero', this.count );
            } else if ( this.count === 1 ) {
                this.$('.msg-0').hide();
                this.$('.msg-1').show();
                this.$('.msg-s').hide();
            } else {
                this.$('.messagecount').text( this.count );
                this.$('.msg-0').hide();
                this.$('.msg-1').hide();
                this.$('.msg-s').show();
            }
        }
    });

    return MessageCounterView;
});

