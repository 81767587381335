define('views/popin',[
       'Backbone',
       'jQuery',
       'underscore',
       'views/myghtyboard/edit'
], function(Backbone, $, _, EditView ) {
    "use strict";
    var NEXT_PAGE_MARGIN = 20;
    var PopinView = Backbone.View.extend({
        events : {
            'click .popin-submit' : 'send',
            'click .back' : 'hide',
            'click .loader' : 'hide',
            'click .comment-displayer' : 'addAPage'
        },
        className : 'popin',
        template : (
            '<div class="back" ></div>' +
            '<div class="loader" ></div>' +
            '<div class="container" >' +
            '<div class="content" ></div>' +
            '</div>'
        ),
        initialize : function( options ) {
            this.page = 0;
            $.get(options.url).done( _.bind( this.gotCover, this));
            this.on( 'page-loading', this.scrollOff, this);
            this.on( 'page-loading', this.loadingIndicatorStart, this);

            this.on( 'page-loaded', this.detectEnd, this);
            this.on( 'page-loaded', this.loadingIndicatorEnd, this);
            this.on( 'page-loaded', this.scrollOn, this);
        },
        scrollOn : function() {
            this.$('.content').on('scroll', this.detectNextPage.bind(this));
        },
        scrollOff : function() {
            this.$('.content').off('scroll');
        },
        loadingIndicatorStart: function() {
            this.$('.add-page').addClass('loading');
        },
        loadingIndicatorEnd: function() {
            this.$('.add-page').removeClass('loading');
        },
        detectEnd: function() {
            if ( this.$('.ended').length !== 0 ) {
                this.page = -1;
                this.scrollOff();
                this.$('.add-page').hide();
            }
        },
        detectNextPage : function(ev) {
            var target = $(ev.currentTarget);
            var max = target.prop('scrollHeight');
            var pos = target.scrollTop() + target.height();
            if ( max - pos < NEXT_PAGE_MARGIN ) {
                this.addAPage();
            }
            return false;
        },
        addAPage: function(ev){
            if ( ev ) {
                $(ev.target).blur();
            }
            if ( this.page === -1 ) {
                return false;
            }
            this.trigger('page-loading');
            var page = this.page += 1;
            var url = this.pagesUrl + '?page=' + page;
            _.delay(
            function(){ $.get(url, _.bind( this.gotPage, this)) ; }.bind(this),
            1000);

            return false;
        },
        gotPage : function( data ) {
            var dest = this.$('.comment');
            var position = dest.scrollTop();
            dest
            .append(data)
            .scrollTop( position );
            this.trigger('page-loaded');
        },
        gotCover : function(data){
            this.$('.content').append( data);
            this.editView = new EditView({ el : this.$( '.forum-edit')  });
            this.$('.container').show();
            this.resize();
            this.$('.loader').hide();
            this.pagesUrl = this.$('.comment-displayer').attr('href');
            this.trigger( 'page-loaded');
        },
        render: function() {
            this.$el.append( this.template);
            this.$('.container').hide();
            this.$('.back').fadeTo(500,0.7);
            this.$el
            .show()
            .appendTo('body');
            return this;
        },
        resize: function() {
            this.$('.container')
            .css('left', ( $('body').width() - this.$('.container').width() ) / 2);
        },
        show: function() {
            this.$el.show();
            return false;
        },
        hide: function(){
            this.$el.hide();
            return false;
        },
        send : function() {
            var postForm = {};
            $.each(this.$('form').serializeArray(), function(i, field){
                postForm[field.name] = field.value;
            });
            var action = this.$('form').attr('action');
            var request = $.ajax({
                type: "POST",
                url: action,
                data: postForm,
                dataType: "html"
            })
            .then( function(){
                this.destroy();
                alert("Message ajouté");
            }.bind( this),
            function(jqXHR, textStatus){
                alert("Request failed: " + jqXHR.responseText);
            });
            return false;
        },
        destroy: function(){
            this.trigger('destroy');
            this.unbind();
            this.$el.remove();
        }
    });
    return PopinView;
});



