
define('models/mp3s',[
       'Backbone'
], function( Backbone ) {
    "use strict";
    // --------------------------- Models
    var Mp3 = Backbone.Model.extend({
        defaults : {
            'performer': '',
            'title' : '',
            'ratingUrl' : '',
            'icone' : '',
            'rating' : null,
            'filemedia' : []
        },
        _getRating : function( variable) {
            var rating = this.get( 'rating' );
            if (!rating ) {
                return null;
            } else {
                return rating[ variable ];
            }
        },
        _setRating : function( variable, value) {
            var rating = this.get( 'rating' );
            if (!rating ) {
                rating = {
                    'prefered' : null,
                    'rating' : 0
                };
            }
            rating[variable] = value;
            this.set( 'rating', rating);
            this.trigger( 'change:rating:' + variable, this, value );
        },
        isInMyMusibox: function() {
            return this._getRating('in_my_musibox');
        },
        setInMyMusibox: function(val) {
            this._setRating( 'in_my_musibox', val);
        },
        getPrefered: function() {
            return this._getRating('prefered');
        },
        setPrefered: function( preferedId ) {
            this._setRating( 'prefered' , preferedId);
        },
        getRating : function() {
            return this._getRating('rating');
        },
        setRating: function( rating ) {
            this._setRating( 'rating' , rating);
        }
    });
    var Mp3List = Backbone.Collection.extend({
        model : Mp3
    });
    var Mp3s = new Mp3List();

    return Mp3s;
});

