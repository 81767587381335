define('views/filepublishing/main',[
       'Backbone',
       'jQuery',
       'underscore',
       'views/filepublishing/mp3',
       'views/filepublishing/favorites'
], function( Backbone, $, _, Mp3Views, FavoriteView ) {
    "use strict";
    var SearchBox = Backbone.View.extend({
        events : {
            'click .submit' : 'send'
        },
        send : function() {
            this.$el.submit();
            return false;
        }
    });
    var FilepublishingBaseView = Backbone.View.extend({
        initialize : function() {
            this.searchBox = new SearchBox({ el: $('#search-box') });
        },
        addAll : function( Mp3s ) {
            this.mp3views = Mp3s.map(function(mp3) {
                return new this.Mp3View({ model : mp3 })
                .on( 'play' , this.play, this)
                .render();
            }, this);
        },
        play : function( mp3, filemedia ) {
            this.trigger( 'play', mp3, filemedia);
        },
        disablePlayers: function() {
        }
    });
    var FilepublishingAnonView = FilepublishingBaseView.extend({
        Mp3View: Mp3Views.anon,
        disablePlayers : function() {
            _.each( this.mp3views, function( v ) {
                v.disable();
            });
        }
    });
    var FilepublishingView = FilepublishingBaseView.extend({
        Mp3View: Mp3Views.logged,
        initialize: function() {
            FilepublishingView.__super__.initialize.call(this);
            this.favorites = new FavoriteView();
        }
    });
    return {
        'anon': FilepublishingAnonView,
        'logged': FilepublishingView
    };
});

