(function(){
    window.twttr = window.twttr || {
        widgets: {
            load: function() {}
        }
    };
})();

define("social/twitter", (function (global) {
    return function () {
        var ret, fn;
        return ret || global.twttr;
    };
}(this)));

