define('apps/stream',[
       'jQuery',
       'social/facebook',
       'compat/console',
       'compat/json'
], function($, FB, console, JSON) {
    "use strict";
    var onLike = function(href, widget) {
        var promise = $.ajax({
            'type' : 'POST',
            'url' : '/stream/like',
            'data': JSON.stringify({
                url: href
            })
        });
        promise.fail(function(data, status, error ) {
            console.log(error);
        });
    };
    FB.Event.subscribe('edge.create', onLike);
});

