define('views/myghtyboard/edit',[
       'Backbone',
       'jQuery'
], function( Backbone, $ ) {
    "use strict";
    var EditView = Backbone.View.extend({
        events : {
            'click .bold' : 'addStrong',
            'click .italic' : 'addEmphasis',
            'click .underline' : 'addUnderline',
            'click .omg' : 'addOmg',
            'click .evil' : 'addEvil',
            'click .nice' : 'addNice',
            'click .twisted' : 'addTwisted',
            'click .grin' : 'addGrin',
            'click .cool' : 'addCool',
            'click .image' : 'addImage',
            'click .quote' : 'addQuote',
            'click .code' : 'addCode'
        },
        addTag: function( tag ) {
            this.addToTarget('<' + tag + '></' + tag + '>');
        },
        addSmiley: function( smiley) {
            this.addToTarget( ':' + smiley + ':');
        },
        addToTarget: function( str ) {
            var target = this.$('textarea');
            target.val( target.val() + str );
            target.focus();
        },
        exit: function( ev) {
            $(ev.currentTarget).blur();
            return false;
        },
        addEmphasis: function(ev) {
            this.addTag('em');
            return this.exit( ev);
        },
        addStrong: function(ev) {
            this.addTag('strong');
            return this.exit( ev);
        },
        addUnderline: function(ev) {
            this.addTag('u');
            return this.exit( ev);
        },
        addImage: function(ev) {
            this.addToTarget('<img src="" alt="" />');
            return this.exit( ev);
        },
        addCode: function(ev) {
            this.addToTarget('[code][/code]');
            return this.exit( ev);
        },
        addQuote: function(ev) {
            this.addTag('blockquote');
            return this.exit( ev);
        },
        addNice: function(ev) {
            this.addSmiley('nice');
            return this.exit( ev);
        },
        addTwisted: function(ev) {
            this.addSmiley('twisted');
            return this.exit( ev);
        },
        addEvil: function(ev) {
            this.addSmiley('evil');
            return this.exit( ev);
        },
        addCool: function(ev) {
            this.addSmiley('cool');
            return this.exit( ev);
        },
        addOmg: function(ev) {
            this.addSmiley('omg');
            return this.exit( ev);
        },
        addGrin: function(ev) {
            this.addSmiley('grin');
            return this.exit( ev);
        }
    });
    return EditView;
});

