define('views/performances',[
       'Backbone',
       'views/filepublishing/itembar',
       'underscore',
       'libs/jquery/tipsy'
], function(Backbone, ItemBar, _,$ts) {
    "use strict";
    var PerformanceView = Backbone.View.extend({
        initialize: function() {
            this.$('.label').tipsy({
                title : 'title',
                gravity: 's'
            });
            this.$('.list img')
            .tipsy({
                live: true,
                title : 'alt',
                gravity: $ts.autoWE
            })
            .click( function() {
                $ts.revalidate();
            });
            this.tops = _.reduce(
                [ 'rating', 'broadcast', 'download' ],
                function( memo, item) {
                    var target = '.top-' + item;
                    var bar = memo[item] = new ItemBar({ el: this.$('.select '+target) });
                    bar
                    .on('open', function() {
                        this.$('.albums ' + target).addClass('album_hover');
                    }, this)
                    .on('closed', function() {
                        this.$('.albums ' + target).removeClass('album_hover');
                    }, this);
                    return memo;
                },
                {},
                this);
        }
    });

    return function( el) {
        return new PerformanceView({ el : el });
    };
});

