/*globals require: true */
define('views/filepublishing/mp3',[
       'Backbone',
       'underscore',
       'jQuery',
       'views/filepublishing/rating',
       'views/filepublishing/player',
       'views/filepublishing/prefered',
       'views/filepublishing/itembar',
       'views/filepublishing/inmymusibox',
       'views/filepublishing/infoplus',
       'views/popin',
       'libs/jquery/tipsy',
       'libs/jquery/jquery-ui'
], function( Backbone, _, $, RatingViews, FilemediaPlayerView,
            PreferedSelectors, ItemBar, InMyMusiboxView, infoplus_factory,
            PopinView, $tp, $ui) {
    "use strict";
    var RatingView = RatingViews.Listing;
    var PreferedSelectorView = PreferedSelectors.Listing;

    var Mp3BaseView = Backbone.View.extend({
        events : {
            'click .popin-forum' : 'launchPopin',
            'click .video-link' : 'launchVideo'
        },
        initialize : function() {
            this.setElement('#titre-' + this.model.id );
            this.filemedias = new FilemediaPlayerView({
                el: this.$('.player-selector'),
                model: this.model
            });
            this.$('.tip').tipsy({
                title: function() {
                    return $(this).find('span.tip-text').text();
                },
                live: true,
                gravity: 's'
            });
            this.filemedias.on( 'play', this.onPlay, this);
            this.infoBox = infoplus_factory({ el:this.$('.info-box') });
            this.players = {};

            this.popin = null;
        },
        render: function() {
            this.filemedias.render();
            if ( this.infoBox) {
                this.infoBox.render();
            }
            return this;
        },
        onPlay : function( fm ) {
            this.trigger( 'play', this.model, fm);
            //console.log( 'play', 'mp3' )
            _.defer( function() {
                this.filemedias.$('.icon').effect(
                    'transfer',
                    {
                        to: $('.transfer-effect-target'),
                        className: 'transfert-icon-play'
                    },
                    500);
            }.bind( this));
        },
        disable : function() {
            this.filemedias.disable();
        },
        launchPopin: function( ev) {
            if( this.popin) {
                this.popin.show();
            } else {
                var url = $(ev.currentTarget).attr('href');
                this.popin = new PopinView({ url : url });
                this.popin.render().on('destroy', function() { this.popin = null ; }, this);
            }
            return false;
        },
        launchVideo: function(ev) {
            var url = $(ev.currentTarget).attr('href');
            var popup = window.open( url,'video','width=490,height=280,menubar=no,toolbar=no,location=no,directories=no');
            if (popup.focus) {
                popup.focus();
            }
            return false;
        }
    });
    var Mp3AnonView = Mp3BaseView.extend({
        events : _.extend( {
            'click .rating-selector': 'intercept',
            'click .prefered-selector': 'intercept',
            'click .in-my-musibox': 'intercept'
        }, Mp3BaseView.prototype.events),
        intercept: function() {
            return false;
        }
    });
    var Mp3View = Mp3BaseView.extend({
        initialize : function() {
            Mp3View.__super__.initialize.call( this);
            this.rating = new RatingView({
                model : this.model,
                el: this.$('.rating-selector')
            });

            this.preferedSelector = new PreferedSelectorView({
                el: this.$('.prefered-selector'),
                model: this.model
            });
            this.downloads = new ItemBar({
                el: this.$('.download-selector')
            });
            this.inMyMusibox = new InMyMusiboxView({
                el: this.$('.in-my-musibox'),
                model: this.model
            });

            this.model.on( 'change:rating:prefered', this.updatePrefered, this);
            this.model.on( 'change:rating:rating', this.updateRating, this);
            this.model.on( 'change:rating:in_my_musibox', this.updateInMyMusibox, this);
        },
        updateInMyMusibox: function() {
                this._update({ in_my_musibox: this.model.isInMyMusibox() && 'true' });
        },
        updatePrefered: function() {
            this._update({ prefered: this.model.getPrefered() });
        },
        updateRating: function() {
            this._update({ rating: this.model.getRating() });
        },
        _update: function( data ) {
            return $.get( this.model.get('ratingUrl'), data);
        },
        render : function() {
            Mp3View.__super__.render.call( this);
            this.rating.render();
            this.preferedSelector.render();
            this.inMyMusibox.render();
            return this;
        }
    });
    return {
        'anon' : Mp3AnonView,
        'logged': Mp3View
    };
});

