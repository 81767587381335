
define('views/filepublishing/prefered',[
       'views/filepublishing/itembar',
       'underscore',
       'jQuery'
], function( ItemBar, _, $) {
    "use strict";
    var BasePreferedSelectorView = ItemBar.extend({
        events : _.extend({
            'click .player' : 'select'
        }, ItemBar.prototype.events),
        initialize : function( options ) {
            this.model.on( 'change:rating:prefered', this.update, this);
        },
        update : function() {
            var prefered = this.model.getPrefered();
            if ( prefered ) {
                this.$el.addClass('set').removeClass('unset');
                this.$('.player').removeClass('selected');
                this.$('#prefered-'+prefered).addClass('selected');
            } else {
                this.$el.addClass('unset').removeClass('set');
            }
        },
        select : function(ev) {
            var prefered = $(ev.currentTarget)
            .find('.prefered')
            .attr('href').split('=').pop();
            this.model.setPrefered( prefered);
            return false;
        },
        render: function() {
            this.update();
            return this;
        }
    });
    var PreferedSelectorView = BasePreferedSelectorView.extend({
    });
    var PlayerPreferedSelectorView = BasePreferedSelectorView.extend({
    });
    return {
        'Listing' : PreferedSelectorView
    };
});

