define('views/filepublishing/infoplus',[
       'views/filepublishing/itembar',
       'underscore',
       'jQuery',
       'libs/jquery/tipsy'
], function( ItemBar, _, $, $tp ) {
    "use strict";
    var InfoPlusEditView = ItemBar.extend({
        MAX_LENGTH: 400,
        events : _.extend({
            'click textarea' : 'forceOpen',
            'keyup textarea' : 'refreshLetterCount',
            'click .okbutton' : 'synch'
        }, ItemBar.prototype.events),
        forceOpen : function() {
            this.setOpen(true);
            return true;
        },
        refreshLetterCount : _.debounce( function() {
            var count = this.$('textarea').val().length;
            this.$('.letter-count').text( count);
            if (count > this.MAX_LENGTH && ! this._over) {
                this._over = true;
                this.$el.addClass('warning');
            } else if ( this._over && count <= this.MAX_LENGTH) {
                this._over = false;
                this.$el.removeClass('warning');
            }
        },  200),
        render: function() {
            InfoPlusEditView.__super__.render.call(this);
            this.$('.letter-total').text( this.MAX_LENGTH );
            this.refreshLetterCount.call(this);
            return this;
        },
        synch: function(ev) {
            var form = this.$('form');
            var info_plus = this.$('textarea[name="info_plus"]').val();
            if ( info_plus.length > this.MAX_LENGTH) {
                return false;
            }
            var values = [
                { name : 'info_plus' , value : info_plus }
            ];
            var array = form.serializeArray();
            $.post( form.attr('action'),
                   values.concat( form.serializeArray()),
                   function() {
                       this.setOpen(false);
                   }.bind( this)
            );
            return false;
        }
    });
    var factory = function(options) {
        if ( options.el.find('form').length === 1 ) {
            return new InfoPlusEditView(options);
        } else if ( options.el.find('.label').length === 1 ) {
            return new ItemBar(options);
        }
        return null;
    };
    return factory;
});

