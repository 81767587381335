define('apps/player',[
       'views/playlist',
       'domReady!'
] , function( PlaylistView ) {
    "use strict";
    return new PlaylistView({
        'autoPlay' : true,
        'wmode' : 'window',
        'swfPath' : '/static/mediaplayer.swf',
        'supplied' : 'mp3'
    });
});

