define('views/myghtyboard/command',[
       'Backbone',
       'jQuery',
       'libs/mustache',
       'apps/musibox'
], function(Backbone, $, mustache, musibox ) {
    "use strict";
    var CommandView = Backbone.View.extend({
        events: {
            'click .command' : 'askCommand',
            'click .confirm' : 'sendCommand',
            'click .cancel' : 'cancel'
        },
        template : mustache.compile(
            '<span class="asking-links" >{{ title}} '+
            '<br />' +
            '<a href="{{url}}" class="confirm" >Yes</a> ' +
            '<a href="#" class="cancel" >No</a> '+
            '</span>'
        ),
        initialize : function() {
            this.requestToken = this.$('form.request-token').serialize();
        },
        askCommand: function(ev) {
            var target = $(ev.currentTarget);
            target
            .closest('li')
            .addClass('asking')
            .append( this.template({
                url: target.attr('href'),
                title: target.attr('title')
            }));
            return false;
        },
        remove: function( source){
            source
            .removeClass('asking')
            .find( '.asking-links')
            .remove();
        },
        cancel: function( ev) {
            var target = $(ev.currentTarget);
            this.remove( target.closest('li'));
            return false;
        },
        sendCommand: function(ev) {
            var target = $(ev.currentTarget);
            var parent = target.closest('li');
            var isDeleteTopic = parent.hasClass('delete-topic');
            this.remove(parent);
            var url = target.attr('href');
            $.ajax({
                type: 'POST',
                url : url,
                data : this.requestToken
            })
            .done( function() {
                if ( isDeleteTopic) {
                    musibox.navigate('/forum/');
                } else {
                    musibox.reload();
                }
            });
            return false;
        }
    });
    return function(el) {
        return new CommandView({ el: el });
    };
});

