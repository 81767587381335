define('views/musibox/messages',[
       'jQuery'
], function( $ ) {
    "use strict";
    return function(el) {
        var $el = $(el)
        .on( 'click', '.close', function() {
            $el.remove();
            return false;
        });
    };
});

