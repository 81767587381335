define('views/musibox/loadingindicator',[
       'jQuery'
], function($) {
    "use strict";
    var LoadingIndicator = function() {
        this.$el = $('#loading');
        this.$console = $('<div>').appendTo( this.$el);
    };
    LoadingIndicator.prototype = {
        show: function(){
            this.$el.show();
            return this;
        },
        hide: function(){
            this.$el.fadeOut( 'slow');
            return this;
        },
        clean: function() {
            this.$console.empty();
            return this;
        },
        addLoading: function( text, promise){
            var block = $( '<div>' + text + '</div>').appendTo( this.$console);
            promise.then( function() {
                block.addClass ('success');
            }, function() {
                block.addClass('fail');
            });
            return this;
        }
    };
    return LoadingIndicator;
});

