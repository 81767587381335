define('compat/domparser',[
       'jQuery'
], function($ ) {
    "use strict";
    // Firefox/Opera/IE throw errors on unsupported types

    var parse;
    if ('DOMParser' in window) {
        try {
            // WebKit returns null on unsupported types
            if ((new window.DOMParser()).parseFromString("", "text/html")) {
                // text/html parsing is natively supported
                parse = function(text) {
                    var parser = new window.DOMParser();
                    return parser.parseFromString(text, 'text/html');
                };
            }
        } catch (ex) {
        }
    } else if ('ActiveXObject' in window) {
        parse = function( text ) {
            var parser = new ActiveXObject("Microsoft.XMLDOM");
            parser.async=false;
            parser.loadXML(text);
            return parser;
        };
    }

    if (!parse) {
        parse = function( text) {
            return text;
        };
    }

    return function(text) {
        return $(parse(text));
    };
});

