define('apps/musiboxplay',[
       'Backbone',
       'underscore',
       'jQuery',
       'libs/jquery/sparkline',
       'libs/jquery/tipsy',
       'apps/musibox',
       'views/search'
], function(Backbone, _, $, $sp, $ts, musibox, SearchBar ){
    "use strict";
    var MusiboxPlayView = Backbone.View.extend({
        events : {
            'click #label-play' : 'showPlay',
            'click #label-club' : 'showClub',
            'click .item' : 'goToUrl'
        },
        initialize: function() {
            this.clubShown = false;
            this._spark( 'play');
            this._show( 'play', 'club');

            this.$('.label').tipsy({
                gravity: 's'
            });
            this.$('.heading .users').tipsy({
                title: function() {
                    return $(this).find('.tip-text').html();
                },
                html: true,
                gravity: 's'
            });
            this.search = new SearchBar({ el : this.$('.filter-box') })
            .on( 'filter', this.filter, this)
            .on( 'clear', this.filter, this);
        },
        goToUrl: function( ev ){
            var url = $(ev.currentTarget).find('.link').attr('href');
            musibox.navigate(url );
            return false;
        },
        showPlay: function( ev ){
            $(ev.currentTarget).blur();
            this._show( 'play', 'club');
            return false;
        },
        showClub: function(ev) {
            $(ev.currentTarget).blur();
            this._show( 'club', 'play');
            if ( ! this.clubShown) {
                this._spark( 'club');
                this.clubShown= true;
            }
            return false;
        },
        _spark :  function( prefix) {
            var sparks = this.$('#ranking-' + prefix)
            .find('.sparkline')
            .sparkline("html", {
                type: "bar",
                barColor: "green",
                zeroColor: "grey",
                zeroAxis: true,
                minSpotColor: -20,
                maxSpotColor: 20
            })
            .find('canvas')
            .css( 'verticalAlign', 'middle');
        },
        _show: function( shown, hidden ) {
            this.$('#ranking-' + shown ).show();
            this.$('#ranking-' + hidden ).hide();
            this.$('#label-' + shown ).addClass('active');
            this.$('#label-' + hidden ).removeClass('active');
        },
        filter: function( criteria) {
            if ( _.isUndefined(criteria)) {
                this.$('.item').show();
            } else {
                criteria = criteria.toLowerCase();
                this.$('.item').each( function( i, e) {
                    e = $(e);
                    var title = e.find('.title').text().toLowerCase();
                    if ( title.indexOf(criteria) !== -1 ) {
                        e.show();
                    } else {
                        e.hide();
                    }
                });
            }
        }
    });
    return function(el) {
        return new MusiboxPlayView({ el : el });
    };
});

