define('views/invoice/pay',[
       'jQuery'
], function($) {
    "use strict";
    return function( el ) {
        el = $(el);
        var refresh = function () {
            el.toggle( $('#paypal-button').prop('checked') );
        };
        refresh();
        $('#pay-method-selector').on( 'change', 'input[name="method"]', refresh);
    };
});

