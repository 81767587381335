define('views/label/mp3form',[
       'Backbone',
       'underscore',
       'jQuery',
       'libs/mustache',
       'apps/player',
       'apps/musibox',
       'compat/formdata',
       'libs/jquery/jquery-ui'
], function( Backbone, _,$, mustache, Player, musibox, FormData, $ui) {
    "use strict";
    var BaseEditView = Backbone.View.extend({
        events : {
            'click .save': 'send'
        },
        initialize: function() {
            this.$('.save').prop('disabled',false);
        },
        send: function(ev){
            if ( !FormData ) {
                return true;
            }
            var target = $(ev.currentTarget).prop('disabled', true);

            var formData = new FormData( this.$el.get(0) );
            var process = false;

            var progressBar = $('<div>')
            .insertBefore( this.$('.save'))
            .progressbar()
            .progressbar('enable');

            $.ajax({
                type: 'POST',
                url: this.$el.attr('action'),
                data: formData,
                contentType: process,
                processData: process,
                xhr: function() {
                    var xhr = $.ajaxSettings.xhr();
                    xhr.upload.onprogress = function( event) {
                        var progress = event.loaded / event.total;
                        progressBar.progressbar('value', progress * 100);
                    }.bind( this);
                    return xhr;
                }
            })
            .done( this.success.bind( this))
            .fail( this.fail.bind( this))
            .always( function() {
                target.prop('disabled', false);
                progressBar.remove();
            });
            return false;
        },
        success: function() {
            //do nothing.
        },
        fail: function( xhr) {
            if ( xhr.getResponseHeader('Content-Type') !== 'application/json' ) {
                this.appendErrors({
                    __all__ : [ 'Error getting response' ]
                });
                return;
            }
            var errors;
            try {
                 errors = JSON.parse( xhr.responseText);
            } catch ( e ) {
                //server lied! it did not send valid JSON.
                //Should not happen often
                errors = {
                    __all__ : [ 'Failed to decode response' ]
                };
            }
            this.resetErrors();
            this.appendErrors( errors);
        },
        errorsTemplate : mustache.compile(
            '<ul class="errorslist">' +
            '{{#errors}}<li>{{ . }}</li>{{/errors}}' +
            '</ul>'
        ),
        resetErrors: function() {
            this.$('.errorslist').remove();
        },
        appendErrors: function( errors ) {
            if ( '__all__' in errors) {
                this.$('.mp3-form').append(
                    this.errorsTemplate({
                    errors: errors.__all__
                }));
                delete errors.__all__;
            }
            _.each( errors, function( errorsText, elementName ){
                this.$('input[name="'+elementName+'"]')
                .closest('td')
                .prepend( this.errorsTemplate({
                    'errors' : errorsText
                }));
            }, this);
        }
    });
    var CreateView = BaseEditView.extend({
        success: function( body, status, xhr) {
            var url = body.mp3_url;
            if ( url ) {
                musibox.navigate( url);
            }
        }
    });
    var EditView = BaseEditView.extend({
        events: _.extend({
            'click .listen-link' : 'listen'
        }, BaseEditView.prototype.events),
        iconeTemplate : mustache.compile(
            '<div class="icone" ><img src="{{ src }}" alt="icone" /></div>'
        ),
        initialize : function() {
            EditView.__super__.initialize.call( this);
            var iconeEl = this.$('[name="icone"]').closest('td');
            var posterURL = iconeEl.find('a').attr('href');
            iconeEl.append( this.iconeTemplate({ src : posterURL }));

            this.media = {
                artist: this.$('input[name="performer"]').val(),
                title: this.$('input[name="title"]').val(),
                poster: posterURL
            };
        },
        success: function(body) {
            if ( 'next' in body) {
                musibox.navigate(body.next);
            }
        },
        listen : function( ev ){
            var $el = $(ev.currentTarget);
            var form = $el.closest('.form_upload');
            var description = form.find('input[name="description"]').val();
            if ( $el.hasClass('sample')) {
                description = 'Extract ' + description ;
            }

            var media = _.extend({
                dl: form.find('.download-link').attr('href'),
                mp3: $el.attr('href'),
                description: description
            },
            this.media);

            Player.add( media, true);
            return false;
        }
    });
    return function( el ) {
        var $el = $(el);
        if ( $el.hasClass('create')) {
            return new CreateView({ el: el });
        } else {
            return new EditView({ el : el});
        }
    };
});

