define('compat/json',[
       'underscore',
       'jQuery'
], function(_, $) {
    "use strict";
    if (window.JSON) {
        return window.JSON;
    }
    var dumpObject = function( obj ) {
        var inside = _.map( obj, function( value, key ) {
            return '"' + key + '":' + stringify( value );
        })
        .join(',');
        return '{' + inside + '}';
    };
    var dumpArray =  function( list ) {
        var inside = _.map( list, stringify)
        .join(',');
        return '[' + inside + ']';
    };
    var stringify = function(value) {
        if ( _.isObject(value)) {
            return dumpObject( value);
        } else if ( _.isArray( value )) {
            return dumpArray( value );
        } else if ( _.isString( value)) {
            return '"' + value + '"';
        } else if ( _.isNumber( value) || _.isBoolean(value) ) {
            return value.toString();
        } else {
            return 'null';
        }
    };
    return {
        parse: $.parseJSON,
        stringify : stringify
    };
});

