define('views/search',[
       'Backbone',
       'underscore',
       'jQuery'
], function( Backbone, _, $) {
    "use strict";
    var SearchBar = Backbone.View.extend({
        events : {
            'click .clear' : 'clear',
            'keyup input.filter' : 'filter'
        },
        timeout: 400,
        initialize: function(){
            this.sendFilter = _.debounce( this._sendFilter.bind(this), this.timeout);
        },
        clear: function() {
            this.$('.filter').val('');
            this.val = '';
            this.trigger('clear');
            return false;
        },
        filter : function(ev) {
            var val = $(ev.currentTarget).val();
            this.val = val;
            if( val === '') {
                this.clear();
            } else {
                this.sendFilter( 'filter', val);
            }
            return true;
        },
        _sendFilter: function() {
            if ( this.val) {
                this.trigger( 'filter', this.val);
            }
        }
    });
    return SearchBar;
});

