define('views/musibox/chat',[
       'Backbone',
       'jQuery',
       'underscore'
], function( Backbone, $, _) {
    "use strict";
    var ChatView = Backbone.View.extend({
        events: {
            'click #chat-label' : 'toggleChat'
        },
        initialize: function() {
            this.shown = false;
            this.setElement('#chat');
            this.chatInterface = _.clone(Backbone.Events);
            window.chatInterface = window.chatInterface || new $.Deferred() ;
            window.chatInterface.resolve( this.chatInterface );
            this.chatInterface
            .on('new-message', this.blink, this)
            .on('shown', this.unblink, this);
            /*
            //  blink the chat after a first message has been sent
            .on('send-message', function() {
                this.listening = true;
            }, this);
            this.listening = false;
           */

            this.listening = true;
        },
        blink: function() {
            if ( this.listening && ! this.shown) {
                this.$el.addClass('alert');
            }
        },
        unblink: function() {
            this.$el.removeClass('alert');
        },
        toggle : function( shown ){
            this.shown = shown;
            if ( this.shown ) {
                this.$el.addClass('shown');
                this.chatInterface.trigger('shown');
            } else {
                this.$el.removeClass('shown');
            }
        },
        toggleChat : function() {
            if ( this.shown) {
                this.$("#chat-content").slideToggle( function() {
                    this.toggle( false);
                }.bind(this));
            } else {
                this.$("#chat-content").slideToggle( function() {
                    this.toggle( true);
                }.bind(this));
            }
        }
    });
    return ChatView;
});

