define('apps/social',[
       'social/twitter',
       'social/facebook',
       'social/google'
], function(twitter, facebook, google) {
    "use strict";
    var refresh = function() {
        if (twitter && twitter.widgets ) {
            twitter.widgets.load();
        }
        facebook.XFBML.parse();
        google.plusone.go();
    };
    return {
        refresh: refresh
    };
});

