define('views/autocomplete',[
       'jQuery',
       'libs/jquery/jquery-ui',
       'domReady!'
], function( $ ) {
    "use strict";
    return function( element ) {
        var $el = $(element);
        return $el.find( '.input' ).autocomplete({
            delay:100,
            select: function(ev,ui) {
                if (ui.item) {
                    $el.find( '.store' )
                    .attr( 'value', ui.item.id );
                }
            },
            source: $el.data('source'),
            minLength: $el.data('min-length')
        });
    };
});

